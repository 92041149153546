import React from "react";
import { graphql, useStaticQuery, Link } from "gatsby";
import { mapEdgesToNodes } from "../lib/helpers";
import TestimonialShowcaseItem from "./testimonialShowcaseItem";

export const query = graphql`
  query testimonialShowcaseQuery {
    allSanityTestimonial(limit: 4, filter: { name: { ne: "Jan Ivar Christiansen" } }) {
      edges {
        node {
          name
          _id
          image {
            alt
            asset {
              _id
            }
            crop {
              _key
              _type
              top
              bottom
              left
              right
            }
            hotspot {
              _key
              _type
              x
              y
              height
              width
            }
          }
          _rawExcerpt
        }
      }
    }
  }
`;

const TestimonialShowcase = () => {
  let omtaler = useStaticQuery(query);
  omtaler = mapEdgesToNodes(omtaler.allSanityTestimonial);

  const omtale1 = omtaler[0] || [];
  const omtale2 = omtaler[1] || [];
  const omtale3 = omtaler[2] || [];
  const omtale4 = omtaler[3] || [];

  return (
    <div className="omtale-showcase">
      <div className="omtale-showcase__wrapper">
        <h1 className="omtale-showcase__title">Kundeomtaler</h1>
        <TestimonialShowcaseItem omtale={omtale1} placement="top-left" />
        <TestimonialShowcaseItem omtale={omtale2} placement="top-right" />
        <TestimonialShowcaseItem omtale={omtale3} placement="bottom-left" />
        <TestimonialShowcaseItem omtale={omtale4} placement="bottom-right" />
        <Link className="omtale-showcase__link" to="/omtaler">
          Les mer fra omtaler
        </Link>
      </div>
    </div>
  );
};

export default TestimonialShowcase;
