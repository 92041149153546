import React from "react";
import { Link } from "gatsby";
import { buildImageObj } from "../lib/helpers";
import { imageUrlFor } from "../lib/image-url";
import PortableText from "./portableText";

const TestimonialShowcaseItem = ({ placement, omtale }) => {
  return (
    <div
      className={`omtale-showcase__item-wrapper ${placement === "top-left" &&
        "omtale-showcase__item-wrapper--top-left"} ${placement === "top-right" &&
        "omtale-showcase__item-wrapper--top-right"} ${placement === "bottom-right" &&
        "omtale-showcase__item-wrapper--bottom-right"}
        ${placement === "bottom-left" && "omtale-showcase__item-wrapper--bottom-left"}`}
    >
      <div className="omtale-showcase__item">
        <div
          className={`omtale-showcase__item-background ${placement === "top-left" &&
            "omtale-showcase__item-background--top-left"} ${placement === "top-right" &&
            "omtale-showcase__item-background--top-right"} ${placement === "bottom-right" &&
            "omtale-showcase__item-background--bottom-right"}
            ${placement === "bottom-left" && "omtale-showcase__item-background--bottom-left"}`}
        ></div>
        <div className="omtale-showcase__item-content">
          <div className="omtale-showcase__item-person">
            {omtale.image && omtale.image.asset && (
              <img
                className="omtale-showcase__item-image"
                src={imageUrlFor(buildImageObj(omtale.image))
                  .width(50)
                  .height(50)
                  .fit("crop")
                  .auto("format")
                  .url()}
                alt={omtale.image.alt}
              />
            )}
            <span className="omtale-showcase__item-name">{omtale.name}</span>
          </div>
          <div className="omtale-showcase__item-excerpt">
            {omtale._rawExcerpt && <PortableText blocks={omtale._rawExcerpt} />}
          </div>
          <Link className="omtale-showcase__item-link" to={`/omtaler#${omtale.name.split(" ")[0]}`}>
            Les mer fra {`${omtale.name.split(" ")[0]}'s omtale`}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default TestimonialShowcaseItem;
