import { Link } from "gatsby";
import React, { useEffect, useState } from "react";

import { buildImageObj } from "../lib/helpers";
import { imageUrlFor } from "../lib/image-url";
import PortableText from "./portableText";
//import { cn } from "../lib/helpers";

const Hero = ({ title, image, info, link }) => (
  <div className="hero">
    <div className="hero__wrapper">
      <div>
        {image && image.asset && (
          <img
            className="hero__img"
            src={imageUrlFor(buildImageObj(image))
              .width(1000)
              .height(1000)
              .fit("crop")
              .auto("format")
              .url()}
            alt={image.alt}
          />
        )}
      </div>
      <div className="hero__info">
        <h1 className="hero__title">{title}</h1>
        {info && <PortableText blocks={info} />}

        <Link className="hero__button" to={link}>
          Les Mer
        </Link>
      </div>
    </div>
  </div>
);

export default Hero;
