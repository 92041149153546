import { graphql, StaticQuery } from "gatsby";
import React, { useState } from "react";
import { Location } from "@reach/router";
import Layout from "../components/layout";

import "../styles/layout.scss";

const query = graphql`
  query SiteTitleQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
    }
  }
`;

function LayoutContainer(props) {
  const [showNav, setShowNav] = useState(false);
  function handleShowNav() {
    setShowNav(true);
  }
  function handleHideNav() {
    setShowNav(false);
  }
  return (
    <StaticQuery
      query={query}
      render={data => {
        if (!data.site) {
          throw new Error(
            'Missing "Site settings". Open the Studio at http://localhost:3333 and some content in "Site settings"'
          );
        }
        return (
          <Location>
            {({ location }) => (
              <Layout
                {...props}
                showNav={showNav}
                siteTitle={data.site.title}
                onHideNav={handleHideNav}
                onShowNav={handleShowNav}
                location={location}
              />
            )}
          </Location>
        );
      }}
    />
  );
}

export default LayoutContainer;
