import { format } from "date-fns";
import { Link } from "gatsby";
import React from "react";
import { buildImageObj, cn, getBlogUrl } from "../lib/helpers";
import { imageUrlFor } from "../lib/image-url";
import PortableText from "./portableText";

import arrowRight from "../assets/arrow-right.svg";
import runningMan from "../assets/running-icon.svg";
import cyclist from "../assets/cyclist-icon.svg";
import brain from "../assets/brain.svg";

function BlogPostPreview(props) {
  return (
    <Link
      className={props.isInList ? "preview__in-list" : "preview__in-grid"}
      to={getBlogUrl(props.publishedAt, props.slug.current)}
    >
      <div className="preview__lead-media-thumb">
        {props.mainImage && props.mainImage.asset && (
          <img
            className="preview__image"
            src={imageUrlFor(buildImageObj(props.mainImage))
              .width(600)
              .height(Math.floor((9 / 16) * 600))
              .auto("format")
              .url()}
            alt={props.mainImage.alt}
          />
        )}
        <img
          className="preview__icon"
          src={
            props.categories[0].title === "Løpeartikler"
              ? runningMan
              : props.categories[0].title === "Sykkelartikler"
              ? cyclist
              : props.categories[0].title === "Kunnskapslab"
              ? brain
              : ""
          }
          alt="Kategori ikon"
        />
      </div>
      <div className="preview__text">
        <h3 className="preview__title">{props.title}</h3>
        {props._rawExcerpt && (
          <div className="preview__excerpt">
            <PortableText blocks={props._rawExcerpt} />
          </div>
        )}
        <button className="preview__extra-link">
          <span className="prices-page__link-text">Les Mer</span>
          <img src={arrowRight} alt="gå videre til artikkelen" />
        </button>
      </div>
    </Link>
  );
}

export default BlogPostPreview;
