import React, { useEffect, useState } from "react";
import { graphql } from "gatsby";
import {
  mapEdgesToNodes,
  filterOutDocsWithoutSlugs,
  filterOutDocsPublishedInTheFuture
} from "../lib/helpers";
import BlogPostPreviewGrid from "../components/blog-post-preview-grid";
import Container from "../components/container";
import GraphQLErrorList from "../components/graphql-error-list";
import SEO from "../components/seo";
import Layout from "../containers/layout";
import Hero from "../components/hero";
import TestimonialShowcase from "../components/testimonialShowcase";

import "../styles/layout.scss";

export const query = graphql`
  fragment SanityImage on SanityMainImage {
    crop {
      _key
      _type
      top
      bottom
      left
      right
    }
    hotspot {
      _key
      _type
      x
      y
      height
      width
    }
    asset {
      _id
    }
  }

  query IndexPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
    }
    posts: allSanityPost(
      limit: 3
      sort: { fields: [publishedAt], order: DESC }
      filter: { slug: { current: { ne: null } }, publishedAt: { ne: null } }
    ) {
      edges {
        node {
          categories {
            title
          }
          id
          publishedAt
          mainImage {
            ...SanityImage
            alt
          }
          title
          _rawExcerpt
          slug {
            current
          }
        }
      }
    }
    slides: allSanitySlider {
      edges {
        node {
          image {
            alt
            asset {
              url
              _id
            }
            crop {
              _key
              _type
              top
              bottom
              left
              right
            }
            hotspot {
              _key
              _type
              x
              y
              height
              width
            }
          }
          _id
          name
          _key
          url
          _rawBody
        }
      }
    }
  }
`;

const IndexPage = props => {
  const { data, errors } = props;
  const [slider, setSlider] = useState(true);
  const [loop, setLoop] = useState(true);
  const [rerender, setRerender] = useState(true);

  useEffect(() => {
    setLoop(
      setInterval(() => {
        setSlider(!slider);
      }, 7000)
    );

    return clearInterval(loop);
  }, [slider]);

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const site = (data || {}).site;
  const postNodes = (data || {}).posts
    ? mapEdgesToNodes(data.posts)
        .filter(filterOutDocsWithoutSlugs)
        .filter(filterOutDocsPublishedInTheFuture)
    : [];

  const slides = (data || {}).slides ? mapEdgesToNodes(data.slides) : [];

  const slide1 = slides[0] || null;
  const slide2 = slides[1] || null;
  // const slide3 = slides[2] || null;
  // const slide4 = slides[3] || null;

  return (
    <Layout>
      <SEO title={site.title} description={site.description} keywords={site.keywords} />
      {slider ? (
        <Hero title={slide2.name} image={slide2.image} info={slide2._rawBody} link={slide2.url} />
      ) : (
        <Hero title={slide1.name} image={slide1.image} info={slide1._rawBody} link={slide1.url} />
      )}

      <TestimonialShowcase />
      <Container>
        {postNodes && (
          <BlogPostPreviewGrid
            title="Siste blogg innlegg"
            nodes={postNodes}
            browseMoreHref="/blog/"
          />
        )}
      </Container>
    </Layout>
  );
};

export default IndexPage;
